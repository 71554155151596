var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "TimeLine" },
    [
      _c(
        "div",
        { staticClass: "time-bigbox" },
        [
          _vm._l(_vm.flow, function (item, index) {
            return [
              !["sequenceFlow"].includes(item.historyActivityType)
                ? _c("div", { key: index, staticClass: "time-box" }, [
                    _c(
                      "div",
                      {
                        staticClass: "content",
                        class: item.endTime ? "" : "content2",
                      },
                      [
                        [
                          _c("img", {
                            staticClass: "yuan",
                            attrs: {
                              src: require("@/assets/images/touxiang.png"),
                              alt: "",
                              srcset: "",
                            },
                          }),
                          item.endTime
                            ? _c("img", {
                                staticClass: "yuan2",
                                attrs: {
                                  src: require("@/assets/images/newicon1.png"),
                                  alt: "",
                                  srcset: "",
                                },
                              })
                            : _vm._e(),
                          item.historyActivityType == "userTask" &&
                          !item.endTime
                            ? _c("img", {
                                staticClass: "yuan2",
                                attrs: {
                                  src: require("@/assets/images/newicon2.png"),
                                  alt: "",
                                  srcset: "",
                                },
                              })
                            : _vm._e(),
                        ],
                        _c("div", { staticClass: "shangyi" }, [
                          _c("div", { staticClass: "title" }, [
                            _c("div", { staticClass: "h1" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    item.historyActivityType == "endEvent"
                                      ? "结束"
                                      : item.historyActivityName || "未命名"
                                  ) +
                                  "\n              "
                              ),
                            ]),
                            _c("div", { staticClass: "h1 time" }, [
                              _vm._v(_vm._s(item.createTime)),
                            ]),
                          ]),
                          _c("div", [
                            _c("div", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(item.assigneeName) +
                                  "\n                "
                              ),
                              item.endTime &&
                              item.historyActivityType != "startEvent" &&
                              item.historyActivityName != "发起人"
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v("(已处理)")]
                                  )
                                : item.historyActivityType == "userTask" &&
                                  item.historyActivityName != "发起人"
                                ? _c(
                                    "span",
                                    { staticStyle: { "font-size": "12px" } },
                                    [_vm._v("(审批中)")]
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
      _c(
        "el-row",
        {
          staticClass: "custom-time-line",
          staticStyle: { display: "none" },
          attrs: { type: "flex" },
        },
        [
          _c(
            "el-timeline",
            [
              _vm._l(_vm.flow, function (item) {
                return [
                  !["sequenceFlow"].includes(item.historyActivityType)
                    ? _c(
                        "el-timeline-item",
                        {
                          key: item.id,
                          attrs: {
                            timestamp: item.endTime || item.createTime,
                            placement: "top",
                          },
                        },
                        [
                          _c(
                            "el-card",
                            { attrs: { shadow: "never" } },
                            [
                              _c("p", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.assigneeName) +
                                    " 在 [" +
                                    _vm._s(item.createTime) +
                                    "] 开始处理 [" +
                                    _vm._s(
                                      item.historyActivityType == "endEvent"
                                        ? "结束"
                                        : item.historyActivityName || "未命名"
                                    ) +
                                    "] 环节\n            "
                                ),
                              ]),
                              item.historyActivityDurationTime
                                ? _c("p", [
                                    _vm._v(
                                      "\n              任务历时 [" +
                                        _vm._s(
                                          item.historyActivityDurationTime
                                        ) +
                                        "]\n            "
                                    ),
                                  ])
                                : _vm._e(),
                              item.comments && item.comments.length > 0
                                ? [
                                    _vm._l(
                                      item.comments.filter(
                                        (c) => c.action === "AddComment"
                                      ),
                                      function (comment, index) {
                                        return _c(
                                          "p",
                                          { key: index },
                                          [
                                            index < 1
                                              ? [
                                                  _vm.commentMap[comment.type]
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.commentMap[
                                                              comment.type
                                                            ]
                                                          ) +
                                                            ": [" +
                                                            _vm._s(
                                                              comment.fullMessage
                                                            ) +
                                                            "]"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  item.comments.filter(
                                                    (c) =>
                                                      c.action === "AddComment"
                                                  ).length > 1
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#1989fa",
                                                            float: "right",
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleClick,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.toggleText
                                                            ) +
                                                              "\n                    "
                                                          ),
                                                          _c("i", {
                                                            class: [
                                                              _vm.isFlag
                                                                ? "el-icon-arrow-up"
                                                                : "el-icon-arrow-down",
                                                            ],
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  comment.time
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            color: "gray",
                                                            "font-size": "12px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                comment.time
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                            index > 0 && _vm.isFlag
                                              ? [
                                                  _vm.commentMap[comment.type]
                                                    ? _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.commentMap[
                                                              comment.type
                                                            ]
                                                          ) +
                                                            ": [" +
                                                            _vm._s(
                                                              comment.fullMessage
                                                            ) +
                                                            "]"
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                  comment.time
                                                    ? _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            color: "gray",
                                                            "font-size": "12px",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                comment.time
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    item.attachments &&
                                    item.attachments.length > 0
                                      ? [
                                          _c(
                                            "p",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "baseline",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  附件:\n                  "
                                              ),
                                              _c("avue-upload", {
                                                attrs: {
                                                  value: item.attachments,
                                                  props: {
                                                    label: "name",
                                                    value: "url",
                                                  },
                                                  "upload-preview":
                                                    _vm.handleUploadPreview,
                                                  disabled: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                              item.endTime
                                ? _c("p", [
                                    _vm._v(
                                      "结束时间: [" + _vm._s(item.endTime) + "]"
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }