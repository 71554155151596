<template>
  <div class="TimeLine">
    <div class="time-bigbox">
      <template v-for="(item, index) in flow">
        <div
          class="time-box"
          :key="index"
          v-if="!['sequenceFlow'].includes(item.historyActivityType)"
        >
          <div class="content" :class="item.endTime ? '' : 'content2'">
            <!-- <span class="yuan"></span> -->
            <template>
              <img
                class="yuan"
                src="~@/assets/images/touxiang.png"
                alt=""
                srcset=""
              />
              <img
                class="yuan2"
                v-if="item.endTime"
                src="~@/assets/images/newicon1.png"
                alt=""
                srcset=""
              />
              <img
                class="yuan2"
                v-if="item.historyActivityType == 'userTask' && !item.endTime"
                src="~@/assets/images/newicon2.png"
                alt=""
                srcset=""
              />
            </template>
            <div class="shangyi">
              <div class="title">
                <div class="h1">
                  <!-- {{ item.historyActivityType }}
                  {{ item.historyActivityName }} -->
                  {{
                    item.historyActivityType == "endEvent"
                      ? "结束"
                      : item.historyActivityName || "未命名"
                  }}
                </div>
                <div class="h1 time">{{ item.createTime }}</div>
              </div>
              <div>
                <div>
                  {{ item.assigneeName }}
                  <span
                    style="font-size: 12px"
                    v-if="
                      item.endTime &&
                      item.historyActivityType != 'startEvent' &&
                      item.historyActivityName != '发起人'
                    "
                    >(已处理)</span
                  >
                  <span
                    v-else-if="
                      item.historyActivityType == 'userTask' &&
                      item.historyActivityName != '发起人'
                    "
                    style="font-size: 12px"
                    >(审批中)</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <el-row style="display: none" type="flex" class="custom-time-line">
      <el-timeline>
        <template v-for="item in flow">
          <el-timeline-item
            v-if="!['sequenceFlow'].includes(item.historyActivityType)"
            :key="item.id"
            :timestamp="item.endTime || item.createTime"
            placement="top"
          >
            <el-card shadow="never">
              <p>
                {{ item.assigneeName }} 在 [{{ item.createTime }}] 开始处理 [{{
                  item.historyActivityType == "endEvent"
                    ? "结束"
                    : item.historyActivityName || "未命名"
                }}] 环节
              </p>
              <p v-if="item.historyActivityDurationTime">
                任务历时 [{{ item.historyActivityDurationTime }}]
              </p>
              <template v-if="item.comments && item.comments.length > 0">
                <p
                  v-for="(comment, index) in item.comments.filter(
                    (c) => c.action === 'AddComment'
                  )"
                  :key="index"
                >
                  <template v-if="index < 1">
                    <span v-if="commentMap[comment.type]"
                      >{{ commentMap[comment.type] }}: [{{
                        comment.fullMessage
                      }}]</span
                    >
                    <span
                      style="color: #1989fa; float: right"
                      v-if="
                        item.comments.filter((c) => c.action === 'AddComment')
                          .length > 1
                      "
                      @click="handleClick"
                      >{{ toggleText }}
                      <i
                        :class="[
                          isFlag ? 'el-icon-arrow-up' : 'el-icon-arrow-down',
                        ]"
                      ></i>
                    </span>
                    <p style="color: gray; font-size: 12px" v-if="comment.time">
                      {{ comment.time }}
                    </p>
                  </template>
                  <template v-if="index > 0 && isFlag">
                    <span v-if="commentMap[comment.type]"
                      >{{ commentMap[comment.type] }}: [{{
                        comment.fullMessage
                      }}]</span
                    >
                    <p style="color: gray; font-size: 12px" v-if="comment.time">
                      {{ comment.time }}
                    </p>
                  </template>
                </p>
                <template
                  v-if="item.attachments && item.attachments.length > 0"
                >
                  <p style="display: flex; align-items: baseline">
                    附件:
                    <avue-upload
                      :value="item.attachments"
                      :props="{ label: 'name', value: 'url' }"
                      :upload-preview="handleUploadPreview"
                      disabled
                    ></avue-upload>
                  </p>
                </template>
              </template>
              <p v-if="item.endTime">结束时间: [{{ item.endTime }}]</p>
            </el-card>
          </el-timeline-item>
        </template>
      </el-timeline>
    </el-row>
  </div>
</template>

<script>
import exForm from "../../mixins/ex-form";
export default {
  name: "wf-flow",
  mixins: [exForm],
  props: {
    flow: {
      type: Array,
      default: () => {
        return [];
      },
    },
    timeDataList: {
      type: Array,
      default: () => {
        return [
          {
            id: 1,
            year: "2021",
            title: "标题1",
          },
          {
            id: 2,
            year: "2020",
            title: "标题2",
          },
          {
            id: 2,
            year: "2019",
            title: "标题3",
          },
        ];
      },
    },
  },
  data() {
    return {
      isFlag: false,
      toggleText: "展开",
      commentMap: {
        assigneeComment: "变更审核人",
        dispatchComment: "调度",
        transferComment: "转办",
        delegateComment: "委托",
        rollbackComment: "驳回意见",
        terminateComment: "终止意见",
        addMultiInstanceComment: "加签",
        deleteMultiInstanceComment: "减签",
        withdrawComment: "撤销",
        recallComment: "撤回",
        deleteProcessComment: "删除流程",
        comment: "审批意见",
      },
    };
  },
  methods: {
    handleClick() {
      this.isFlag = !this.isFlag;
      this.toggleText = this.isFlag ? "收起" : "展开";
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-time-line {
  ::v-deep .el-timeline-item__tail {
    left: 7px;
    border-left-color: #0070ff;
  }
  ::v-deep .el-timeline-item__node--normal {
    width: 18px;
    height: 18px;
  }

  ::v-deep .el-timeline-item__node {
    background-image: url("~@/assets/reversIcon.png");
  }

  ::v-deep .el-timeline-item__wrapper {
    padding-left: 66px;
  }
}
</style>
<style lang="scss" scoped>
// .time-bigbox {
//   padding: 27px 0;
// }
.time-box {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.content {
  margin-left: 60px;
  // border-left: 3px dashed #e6e6e6;
  border-left: 3px solid #e6e6e6;
  padding-left: 30px;
  position: relative;
  padding-bottom: 50px;
  flex: 1;
  min-height: 80px;
  margin-right: 10px;
}
.content2 {
  border-left: 3px dashed #e6e6e6;
}
.time-box:last-child .content {
  border-left: 2px solid transparent;
}
.shangyi {
  // transform: translateY(-11px);
  padding-top: 20px;
  margin-left: 10px;
}
.yuan {
  position: absolute;
  top: 0;
  left: 0px;
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #fff;
  padding: 10px;
  border-radius: 50%;
  transform: translateX(-56%);
}
.yuan2 {
  position: absolute;
  top: 50px;
  left: 5px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.title {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  color: #000;
  .time {
    color: #999999;
    font-weight: 400;
  }
}
</style>
